.portfolio-section {
  background-color: #ffffff;
  padding: 3rem 0;
  position: relative;
  overflow: hidden;
}

.portfolio-section::before {
  content: '';
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #f0f0f0;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-title {
  font-size: 2.5rem;
  color: #0077b6;
  text-align: center;
  margin-bottom: 3rem;
}

.project-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.project-card {
  flex: 0 1 calc(33.333% - 2rem);
  max-width: 350px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.thumbnail-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}

.project-thumbnail {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.project-info {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.project-title {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.project-category {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.75rem;
}

.project-description {
  font-size: 0.9rem;
  color: #444;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.see-more-link {
  color: #0077b6;
  text-decoration: none;
  font-weight: 600;
}

.see-more-link:hover {
  text-decoration: underline;
}

.visit-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #0077b6;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.visit-button:hover {
  background-color: #005a8c;
}

.visit-button svg {
  margin-right: 0.5rem;
}

.portfolio-description {
  max-width: 800px;
  margin: 0 auto 2rem;
  text-align: center;
  color: #444;
  line-height: 1.6;

  p {
    font-size: 1.2rem;
  }
}

.filter-container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.filter-group label {
  color: #333;
  font-size: 0.9rem;
  cursor: pointer;
}

.filter-checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

@media (max-width: 700px) {
  .portfolio-description {
    padding: 0 1rem;
  }
  
  .filter-container {
    padding: 0 1rem;
    gap: 1rem;
  }
}

@media (max-width: 1100px) {
  .project-card {
    flex: 0 1 calc(50% - 2rem);
  }
}

@media (max-width: 700px) {
  .project-card {
    flex: 0 1 100%;
  }
}