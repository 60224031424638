.home-page {
  font-family: 'Poppins', sans-serif;
  color: #333;



  h3 {
    color: #0077b6;
  }

  .hero {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #f0f0f0;
    padding: 6rem 12rem;
    overflow: hidden;
    position: relative;
    
    
    .hero-content {
      flex: 1;
      z-index: 2;
      max-width: 800px;
      
      h1 {
        font-size: 3.5rem;
        margin-bottom: 1rem;
        line-height: 1.2;
        font-weight: 700;
        background: linear-gradient(45deg, #005f8e, #00a8e8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
      }
      
      p {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        color: #555;
        font-weight: 300;
      }
      
      .hero-skills {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 2.5rem;
        
        span {
          background-color: #0077b6;
          color: #fff;
          padding: 0.6rem 1.2rem;
          border-radius: 25px;
          font-size: 1rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          transition: all 0.3s ease;
          box-shadow: 0 2px 5px rgba(0,0,0,0.1);
          
          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0,0,0,0.15);
          }
          
          svg {
            font-size: 1.2rem;
          }
        }
      }
      
      button {
        font-size: 1.4rem;
        padding: 2rem 3rem;
        background-color: #0077b6;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        
        &:hover {
          background-color: #005f8e;
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0,0,0,0.15);
        }
      }
    }
    
  }

  .hero-image {
    max-width: 350px;
    width: 100%;
    text-align: center;
    z-index: 2;
    
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 10%;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      border: 5px solid #fff;
      transition: all 0.3s ease;
    }
  }

  .cta {
    background-color: #005f8e;
    color: #fff;
    text-align: center;
    
    h2 {
      font-size: 2.5rem;
      color: #fff;
      margin-bottom: 1.5rem;
    }
    
    p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
    
    button {
      font-size: 1.1rem;
      padding: 1rem 2rem;
      background-color: #fff;
      color: #005f8e;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0 2px 5px rgba(0,0,0,0.1);
      
      &:hover {
        background-color: #f0f0f0;
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0,0,0,0.15);
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .home-page {
    .hero {
      padding: 6rem 6rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .home-page {
    .hero {
      padding: 5rem 3rem;
      flex-direction: column;
      text-align: center;
      
      .hero-content {
        margin-bottom: 3rem;
        
        h1 {
          font-size: 3rem;
        }
      }
      
      .hero-image {
        max-width: 300px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home-page {
    section {
      padding: 4rem 1.5rem;
    }
    
    .hero {
      padding: 3rem 1.5rem;
      
      .hero-content {
        h1 {
          font-size: 2.5rem;
        }
        
        p {
          font-size: 1.2rem;
        }
      }
      
      .hero-image {
        max-width: 250px;
      }
    }
    
    .cta {
      h2 {
        font-size: 2rem;
      }
      
      p {
        font-size: 1rem;
      }
    }
    
  }
}

@media screen and (max-width: 480px) {
  .home-page {
    .hero {
      .hero-content {
        h1 {
          font-size: 2rem;
        }
        
        p {
          font-size: 1.1rem;
        }
      }
      
      .hero-image {
        max-width: 200px;
      }
    }
  }
}