.salesforcePage {
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.header {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/EXwqrutVAAA1vIh.jpeg?alt=media&token=513abd68-55ff-4f41-866e-60d3277a698d');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem 2rem;
  text-align: center;
  position: relative;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 119, 182, 0.8));
  z-index: 1;
}

.headerContent {
  position: relative;
  z-index: 2;
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
}

.textContainer {
  animation: fadeIn 1s ease-out;
}

.header h1 {
  font-size: 4rem;
  font-weight: 800;
  color: white;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  background: rgba(0, 119, 182, 0.3);
  padding: 1rem 2rem;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  display: inline-block;
  line-height: 1.2;
}

.header p {
  font-size: 1.8rem;
  color: white;
  margin: 2rem auto;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  padding: 1rem 2rem;
  border-radius: 8px;
  backdrop-filter: blur(3px);
  display: inline-block;
  max-width: 800px;
  line-height: 1.5;
}

.ctaButton {
  background: #f4b41a;
  color: white;
  border: none;
  padding: 1.2rem 2.5rem;
  font-size: 1.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 15px rgba(244, 180, 26, 0.3);
  margin-top: 1rem;
}

.ctaButton:hover {
  transform: translateY(-3px);
  background: #d69b15;
  box-shadow: 0 6px 20px rgba(244, 180, 26, 0.4);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .header {
    padding: 6rem 1rem;
    min-height: 70vh;
  }

  .header h1 {
    font-size: 2.8rem;
    padding: 0.8rem 1.5rem;
  }

  .header p {
    font-size: 1.4rem;
    padding: 0.8rem 1.5rem;
  }

  .ctaButton {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Process Section */
.processSection {
  padding: 6rem 0;
  background: #f8fafc;
}

.processSection h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #0077b6;
  margin-bottom: 1.5rem;
}

.sectionIntro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #4a5568;
  padding: 0 2rem;
}

.processList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.processCard {
  background: white;
  padding: 1.5rem 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.processCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.stepNumber {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  background: #0077b6;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.processIcon {
  font-size: 2.5rem;
  color: #0077b6;
  margin: 1rem 0 1.5rem;
}

.processCard h3 {
  color: #0077b6;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.processCard p {
  color: #4a5568;
  line-height: 1.6;
  font-size: 0.95rem;
  padding: 0 1rem;
}

/* Services Section */
.services {
  padding: 6rem 0;
  background: white;
}

.services h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #0077b6;
  margin-bottom: 3rem;
}

.servicesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.serviceCard {
  background: #f8fafc;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.serviceCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.serviceHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.serviceIcon {
  font-size: 2rem;
  color: #0077b6;
}

.serviceCard h3 {
  font-size: 1.5rem;
  color: #0077b6;
  margin: 0;
}

.serviceCard p {
  color: #4a5568;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: left; /* Ensure paragraph is left-aligned */
}

.serviceCard ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left; /* Ensure list container is left-aligned */
}

.serviceCard li {
  position: relative;
  padding-left: 1.5rem;
  color: #4a5568;
  margin-bottom: 1rem;
  line-height: 1.4;
  text-align: left; /* Ensure list items are left-aligned */
}

.serviceCard li:before {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
  color: #0077b6;
  font-size: 1.2rem;
}

/* Media Queries */
@media screen and (max-width: 1200px) {
  .processList {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1.5rem;
  }

  .servicesGrid {
    grid-template-columns: 1fr;
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .header h1 {
    font-size: 2.5rem;
    padding: 1.5rem;
  }

  .header p {
    font-size: 1.2rem;
    padding: 1rem 1.5rem;
  }

  .processList {
    grid-template-columns: 1fr;
  }

  .processSection h2,
  .services h2 {
    font-size: 2rem;
    padding: 0 1rem;
  }

  .processCard {
    padding: 2rem 1.5rem;
  }

  .sectionIntro {
    font-size: 1.1rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .header h1 {
    font-size: 2rem;
    padding: 1.5rem;
  }

  .header p {
    font-size: 1.1rem;
    padding: 1rem 1.5rem;
  }

  .processCard h3 {
    font-size: 1.2rem;
  }

  .serviceCard {
    padding: 2rem 1.5rem;
  }
}