.portfolioSection {
    background-color: #f9fafb;
    padding: 4rem 1rem;
    text-align: left;
  }
  
  .container {
    max-width: 1280px;
    margin: 0 auto;
  }
  
  .portfolioHeader {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .portfolioTitle {
    font-size: 2.5rem;
    color: #005f8e;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  
  .portfolioDescription {
    max-width: 36rem;
    margin: 0 auto;
    color: #4b5563;
    line-height: 1.6;
    font-size: 1.125rem;
  }
  
  .filterContainer {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 3rem;
    flex-wrap: wrap;
  }
  
  .filterButton {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    background: white;
    border-radius: 9999px;
    font-size: 0.875rem;
    color: #374151;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .filterButton:hover {
    background: #f3f4f6;
  }
  
  .filterButton.active {
    background: #005f8e;
    color: white;
    border-color: #005f8e;
  }
  
  .projectsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0 0.5rem;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .projectCard {
    background: white;
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
  }
  
  .projectCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .projectImage {
    width: 120px;
    min-width: 120px;
    background: #f3f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .projectImage img {
    width: 100%;
    height: auto;
    object-fit: contain;
    transition: transform 0.3s ease;
  }
  
  .projectContent {
    flex: 1;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .projectHeader {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }
  
  .projectTitle {
    font-size: 1.125rem;
    font-weight: 600;
    color: #005f8e;
    margin: 0;
  }
  
  .projectCategory {
    font-size: 0.75rem;
    background: #f3f4f6;
    color: #4b5563;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    white-space: nowrap;
  }
  
  .projectDescription {
    font-size: 0.875rem;
    color: #4b5563;
    line-height: 1.5;
    margin-bottom: 1rem;
    flex-grow: 1;
  }


.projectDescription {
    font-size: 0.875rem;
    color: #4b5563;
    line-height: 1.5;
    margin-bottom: 1rem;
    flex-grow: 1;
    text-align: left;
  }
  
  .visitLinkContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  
  .visitLink {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #005f8e;
    border: 1px solid #005f8e;
    border-radius: 0.5rem;
    color: white;
    text-decoration: none;
    font-size: 0.875rem;
    transition: all 0.2s ease;
    width: fit-content;
  }
  
  .visitLink:hover {
    background: #004365;
    border-color: #004365;
  }
  
  .externalIcon {
    width: 1rem;
    height: 1rem;
  }

  .categoryContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: flex-start;
  }
  
  @media (max-width: 640px) {
    .projectCard {
      flex-direction: column;
    }
  
    .projectImage {
      width: 100%;
      height: 140px;
      padding: 1rem;
    }
  
    .projectImage img {
      max-height: 100%;
      width: auto;
    }
    
    .portfolioTitle {
      font-size: 2rem;
    }
    
    .portfolioDescription {
      font-size: 1rem;
      padding: 0 1rem;
    }
  }