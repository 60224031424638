.footer {
  background-color: #0077b6;
  color: #ffffff;
  padding: 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.copyright {
  font-size: 0.9rem;
  margin: 0;
  opacity: 0.9;
}

.contact-info {
  display: flex;
  gap: 1.5rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.contact-item:hover {
  opacity: 0.8;
}

.contact-item span {
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  .footer-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .contact-info {
    flex-direction: column;
    gap: 0.75rem;
  }
}