.servicesSection {
  padding: 2rem 2rem;
  background: #0077b6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  text-align: center;
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1rem;
}

.subtitle {
  text-align: center;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 4rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.servicesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.serviceCard {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.serviceCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.serviceCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--accent-color);
}

.iconWrapper {
  background: var(--accent-color);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
}

.icon {
  font-size: 1.8rem;
  color: white;
}

.serviceCard h3 {
  font-size: 1.5rem;
  color: #005f8e;
  margin-bottom: 1rem;
}

.serviceCard p {
  color: #64748b;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.learnMore {
  background: transparent;
  color: var(--accent-color);
  border: 2px solid var(--accent-color);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.learnMore:hover {
  background: var(--accent-color);
  color: white;
}

.ctaContainer {
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 1rem;
  margin-top: 4rem;
}

.ctaContainer h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #1e3a8a;
}

.ctaButton {
  background: #0077b6;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ctaButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  background: #005a8a;
}

@media screen and (max-width: 768px) {
  .servicesSection {
    padding: 4rem 1.5rem;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
    margin-bottom: 3rem;
  }

  .servicesGrid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .ctaContainer {
    padding: 2rem;
  }

  .ctaContainer h3 {
    font-size: 1.5rem;
  }
}