.contact-page {
  padding: 6rem 2rem;
  text-align: center;
  background-color: #f8fafc;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: #0077b6;
  }

  .contact-options {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
    max-width: 1000px;
    margin: 0 auto;
  }

  .contact-email, .contact-schedule {
    flex: 1;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
    }
  }

  .contact-icon {
    font-size: 3rem;
    color: #0077b6;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.5rem;
    color: #005f8e;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;
  }

  a {
    color: #005f8e;
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
      color: #112049;
    }
  }

  .contact-divider {
    display: flex;
    align-items: center;
    
    span {
      background-color: #0077b6;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.5rem 1rem;
      border-radius: 20px;
    }
  }

  button {
    background-color: #0077b6;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #005f8e;
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-page {
    padding: 4rem 1.5rem;

    h2 {
      font-size: 2rem;
    }

    .contact-options {
      flex-direction: column;
      align-items: center;
    }

    .contact-divider {
      margin: 1rem 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .contact-page {
    h2 {
      font-size: 1.8rem;
    }

    h3 {
      font-size: 1.3rem;
    }

    p, a {
      font-size: 0.9rem;
    }

    button {
      font-size: 1rem;
      padding: 0.8rem 1.5rem;
    }
  }
}