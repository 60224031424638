.navbar {
  background-color: #0077b6;
  color: #fff;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1000; /* Ensure navbar is above other content */
}

.navbar.scrolled {
  background-color: rgba(0, 119, 182, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.navbar-logo img {
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
}

.navbar-links {
  display: flex;
  gap: 1.5rem;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #fff;
  transition: width 0.3s ease-in-out;
}

.nav-link:hover::after {
  width: 100%;
}

.navbar-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.services-dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  background-color: #005a8a;
  min-width: 220px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
  transform-origin: top center;
  transform: translateX(-50%) translateY(-10px);
}

.services-dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.dropdown-item {
  display: block;
  padding: 0.75rem 1rem;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #0077b6;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001; /* Ensure dropdown is above other content */
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-toggle {
    display: block;
    z-index: 1002; /* Ensure toggle button stays on top */
  }

  .services-dropdown {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .dropdown-menu {
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: none;
    box-shadow: none;
    background-color: transparent;
    min-width: 100%;
    padding-left: 1rem;
    display: block;
    left: 0;
    transform: none;
  }

  .services-dropdown:hover .dropdown-menu {
    transform: none;
  }

  .dropdown-item {
    padding: 0.5rem 0;
  }

  .nav-link::after {
    display: none;
  }
}