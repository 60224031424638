/* Variables */
:root {
  --primary-color: #0077b6;
  --primary-dark: #005f8e;
  --primary-light: rgba(0, 119, 182, 0.1);
  --secondary-color: #f4b41a;
  --text-primary: #333;
  --text-secondary: #4a5568;
  --white: #ffffff;
  --gradient-overlay: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 119, 182, 0.8));
  --card-shadow: 0 4px 20px rgba(0, 119, 182, 0.1);
  --card-shadow-hover: 0 8px 30px rgba(0, 119, 182, 0.2);
  --transition: all 0.3s ease;
}

/* Reset & Base Styles */
.techReviewPage {
  font-family: 'Poppins', sans-serif;
  color: var(--primary-color);
  line-height: 1.6;
}

/* Header Section */
.header {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/businessreview6.webp?alt=media&token=e08a7fc4-e3f6-479c-98fd-adcf229a6757');
  position: relative;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
}

.headerOverlay {
  position: absolute;
  inset: 0;
  background: var(--gradient-overlay);
  z-index: 1;
}

.headerContent {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem;
}

.textContainer {
  opacity: 0;
  animation: slideUp 1s ease-out forwards;
}

.header h1 {
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  font-weight: 800;
  color: var(--white);
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  background: rgba(0, 119, 182, 0.8);
  padding: clamp(1rem, 3vw, 2rem);
  border-radius: 15px;
  backdrop-filter: blur(8px);
  display: inline-block;
}

.header p {
  font-size: clamp(1.2rem, 3vw, 2rem);
  background: rgba(0, 119, 182, 0.8);
  /* background-color: #0077b6; */
    backdrop-filter: blur(8px);
    border-radius: 15px;
    padding: clamp(1rem, 3vw, 2rem);




  color: var(--white);
  margin: 0 auto 2rem;
  max-width: 800px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

/* Services Section */
.services {
  padding: 4rem 2rem;
  background: linear-gradient(180deg, var(--white) 0%, #f8fafc 100%);
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}

h2 {
  text-align: center;
  font-size: clamp(2rem, 4vw, 2.5rem);
  color: var(--primary-color);
  margin-bottom: 4rem;
  font-weight: 700;
}

.servicesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.serviceCard {
  background: var(--white);
  border-radius: 20px;
  padding: 2.5rem;
  transition: var(--transition);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--card-shadow);
  border: 1px solid var(--primary-light);
}

.serviceCard:hover {
  transform: translateY(-5px);
  box-shadow: var(--card-shadow-hover);
}

.serviceHeader {
  text-align: center;
}

.serviceIcon {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.serviceCard h3 {
  color: var(--primary-color);
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.revenueBadge {
  display: inline-block;
  background: var(--primary-light);
  color: var(--primary-color);
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
}

.serviceCard > p {
  color: var(--text-secondary);
  line-height: 1.8;
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.serviceCard ul {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.serviceCard li {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
  font-size: 1.1rem;
  padding: 0.5rem 0;
}

.serviceCard li svg {
  color: var(--primary-color);
  flex-shrink: 0;
}

/* Button Styles */
.primaryButton {
  background: var(--secondary-color);
  color: var(--white);
  border: none;
  padding: 1.2rem 2.5rem;
  font-size: 1.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: var(--transition);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 15px rgba(244, 180, 26, 0.3);
  margin-top: auto;
}

.primaryButton:hover {
  transform: translateY(-3px);
  background: #e3a618;
  box-shadow: 0 6px 20px rgba(244, 180, 26, 0.4);
}

/* Expertise Section */
.expertise {
  padding: 6rem 2rem;
  background: var(--white);
}

.expertiseGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.expertiseCard {
  text-align: center;
  padding: 2rem;
  background: #f8fafc;
  border-radius: 15px;
  transition: var(--transition);
}

.expertiseCard:hover {
  transform: translateY(-5px);
  background: var(--white);
  box-shadow: var(--card-shadow);
}

.expertiseIcon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.expertiseImage {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 119, 182, 0.2);
  transition: var(--transition);
}

.expertiseCard:hover .expertiseImage {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 119, 182, 0.3);
}

.expertiseCard h3 {
  color: var(--primary-dark);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.expertiseCard p {
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .header {
    min-height: 60vh;
  }
  
  .headerContent {
    padding: 2rem 1rem;
  }
  
  .services,
  .expertise {
    padding: 4rem 1rem;
  }
  
  .serviceCard {
    padding: 1.5rem;
  }
  
  .primaryButton {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .header h1 {
    padding: 1rem;
  }
  
  .servicesGrid,
  .expertiseGrid {
    grid-template-columns: 1fr;
  }
  
  .serviceCard {
    min-height: auto;
  }
}