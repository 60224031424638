.pricing-page {
  background-color: #f0f0f0;
    .section {
      padding: 50px 0;
      text-align: center;
  
      h2 {
        font-size: 2rem;
        margin-bottom: 20px;
      }

      img {
        width: 200px;
      }

      .pricing-card-container {
        display: flex;
        margin: 0 10%;
      }
  
      .pricing-card {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin: 20px;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
  
        h3 {
          font-size: 1.5rem;
          margin-bottom: 10px;
        }
  
        p {
          font-size: 1.1rem;
          color: #555;
          margin-bottom: 10px;
          line-height: 1.6;
        }

        .price {
          font-size: 1.3rem;
          color: #008000;
          font-weight: bold;
        }

        
      }
      button {
        background-color: #0077b6;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 5px;
        font-size: 1.2rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
        height: 80px;
        width: 220px;
        font-weight: bold;
        letter-spacing: 1.1px;
        margin: 20px 0px;
  
        &:hover {
          background-color: #005a8a;
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .pricing-page {
        .section {
          padding: 30px 0;
      
          h2, h3 {
            padding: 0 10%;
          }
    
          .pricing-card-container {
            flex-direction: column;
          }
      
          .pricing-card {
            margin: 10px auto;
            width: 90%;
          }
        }
      }
    }
  
  