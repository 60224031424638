.team-page {
  text-align: left;

  h1 {
      font-size: 36px;
  }

  h2 {
      font-size: 24px;
      color: #555;
  }

  h3 {
      font-size: 28px;
      color: #0077b6;
      margin-bottom: 20px;
      margin-top: 0px;
  }

  p {
      font-size: 18px;
      color: #555;
      line-height: 1.6;
      margin-bottom: 20px;
  }

  .center {
      text-align: center;
  }

  section {
      padding: 60px 0;
  }

  .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
  }

  .profile-section {
      display: flex;
      align-items: center;
      gap: 40px;

      .profile-image {
          flex: 1;
          max-width: 400px;

          img {
              width: 100%;
              height: auto;
              border-radius: 10px;
              box-shadow: 0 4px 8px rgba(0,0,0,0.1);
          }
      }

      .profile-content {
          flex: 2;
      }
  }

  .btn-primary {
      display: inline-block;
      padding: 12px 24px;
      background-color: #0077b6;
      color: #fff;
      text-decoration: none;
      border-radius: 5px;
      font-size: 18px;
      transition: background-color 0.3s ease;

      &:hover {
          background-color: #005a8a;
      }
  }

  .skills {
      background-color: #0077b6;

      .skills-list {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          padding: 0;
          list-style: none;

          li {
              background-color: #f0f0f0;
              color: #0077b6;
              padding: 10px 20px;
              border-radius: 25px;
              font-size: 16px;
          }
      }

      h3 {
        color: #f0f0f0;
      }
  }

  .personal {
    background-color: #fff;

    .hobbies {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;

      .hobby-content {
        flex: 1;
        order: 1;

        p {
          margin-bottom: 20px;
        }
      }

      .hobby-image {
        flex: 1;
        order: 2;
        max-width: 400px;

        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
      }
    }

    .social-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 20px;

      .btn-social {
        display: inline-flex;
        align-items: center;
        padding: 10px 15px;
        border-radius: 5px;
        font-size: 16px;
        text-decoration: none;
        color: #fff;
        transition: background-color 0.3s ease;

        svg {
          margin-right: 8px;
        }
      }

      .btn-linkedin {
        background-color: #0077b5;
        &:hover { background-color: #005fa3; }
      }

      .btn-instagram {
        background-color: #e4405f;
        &:hover { background-color: #d63151; }
      }

      .btn-github {
        background-color: #333;
        &:hover { background-color: #2b2b2b; }
      }

      .btn-salesforce {
        background-color: #00a1e0;
        &:hover { background-color: #0088bd; }
      }

      .btn-portfolio {
        background-color: #34a853;
        &:hover { background-color: #2d8f47; }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .team-page {
      .profile-section {
          flex-direction: column;
          text-align: center;

          .profile-image {
              margin-bottom: 30px;
          }
      }

      .personal .hobbies {
        flex-direction: column-reverse;
        text-align: center;
  
        .hobby-image {
          margin-bottom: 20px;
        }
  
        .social-buttons {
          justify-content: center;
        }
      }
  }
}