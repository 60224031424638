.customAppsPage {
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
.header {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/coding_background.webp?alt=media&token=0575556e-a4ca-42a4-b629-51102fd12b11');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem 2rem;
  text-align: center;
  position: relative;
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 119, 182, 0.8));
  z-index: 1;
}

.headerContent {
  position: relative;
  z-index: 2;
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
}

.textContainer {
  animation: fadeIn 1s ease-out;
}

.header h1 {
  font-size: 4rem;
  font-weight: 800;
  color: white;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  background: rgba(0, 119, 182, 0.3);
  padding: 1rem 2rem;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  display: inline-block;
}

.header p {
  font-size: 1.8rem;
  color: white;
  margin-bottom: 2.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  padding: 1rem 2rem;
  border-radius: 8px;
  backdrop-filter: blur(3px);
  display: inline-block;
}

.primaryButton {
  background: #f4b41a;
  color: white;
  border: none;
  padding: 1.2rem 2.5rem;
  font-size: 1.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 15px rgba(0, 119, 182, 0.3);
  backdrop-filter: blur(3px);
}

.primaryButton:hover {
  transform: translateY(-3px);
  background: #d69b15;
  box-shadow: 0 6px 20px rgba(0, 119, 182, 0.4);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .header {
    padding: 6rem 1rem;
    min-height: 70vh;
  }

  .header h1 {
    font-size: 2.8rem;
    padding: 0.8rem 1.5rem;
  }

  .header p {
    font-size: 1.4rem;
    padding: 0.8rem 1.5rem;
  }

  .primaryButton {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }
}

  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  
  /* Platforms Section */
  .platforms {
    padding: 4rem 0;
    background: white;
  }
  
  .platforms h2 {
    text-align: center;
    font-size: 2rem;
    color: #0077b6;
    margin-bottom: 3rem;
  }
  
  .platformGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .platformCard {
    text-align: center;
    padding: 2rem;
    border-radius: 1rem;
    background: #f8fafc;
    transition: transform 0.3s ease;
  }
  
  .platformCard:hover {
    transform: translateY(-5px);
  }
  
  .platformIcon {
    font-size: 2.5rem;
    color: #0077b6;
    margin-bottom: 1rem;
  }
  
  .platformCard h3 {
    font-size: 1.3rem;
    color: #0077b6;
    margin-bottom: 1rem;
  }
  
  .platformCard p {
    color: #4a5568;
    line-height: 1.6;
  }
  
  /* Development Process Section */
  .development {
    padding: 4rem 0;
    background: #f8fafc;
  }
  
  .development h2 {
    text-align: center;
    font-size: 2rem;
    color: #0077b6;
    margin-bottom: 3rem;
  }
  
  .processSteps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .step {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .stepNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #0077b6;
    color: white;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .step h3 {
    color: #0077b6;
    margin-bottom: 1rem;
  }
  
  .step ul {
    list-style: none;
    padding: 0;
  }
  
  .step li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #4a5568;
    margin-bottom: 0.8rem;
  }
  
  .step li svg {
    color: #0077b6;
    flex-shrink: 0;
  }
  
  /* Features Section */
  .features {
    padding: 4rem 0;
    background: white;
  }
  
  .features h2 {
    text-align: center;
    font-size: 2rem;
    color: #0077b6;
    margin-bottom: 3rem;
  }
  
  .featureGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .featureCard {
    text-align: center;
    padding: 2rem;
    border-radius: 1rem;
    background: #f8fafc;
    transition: transform 0.3s ease;
  }
  
  .featureCard:hover {
    transform: translateY(-5px);
  }
  
  .featureIcon {
    font-size: 2rem;
    color: #0077b6;
    margin-bottom: 1rem;
  }
  
  .featureCard h3 {
    color: #0077b6;
    margin-bottom: 1rem;
  }
  
  .featureCard p {
    color: #4a5568;
    line-height: 1.6;
  }
  
  /* Technologies Section */
  .technologies {
    padding: 4rem 0;
    background: #f8fafc;
  }
  
  .technologies h2 {
    text-align: center;
    font-size: 2rem;
    color: #0077b6;
    margin-bottom: 3rem;
  }
  
  .techGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
  }
  
  .techCategory {
    text-align: center;
    padding: 2rem;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .techCategory h3 {
    color: #0077b6;
    margin-bottom: 1rem;
  }
  
  .techCategory ul {
    list-style: none;
    padding: 0;
  }
  
  .techCategory li {
    color: #4a5568;
    margin-bottom: 0.5rem;
  }
  
  /* CTA Section */
  .cta {
    padding: 4rem 0;
    background: #0077b6;
    text-align: center;
    color: white;
  }
  
  .cta h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .cta p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    opacity: 0.9;
  }
  
  .cta .primaryButton {
    background: white;
    color: #0077b6;
  }
  
  .cta .primaryButton:hover {
    background: #f0f9ff;
  }
  
  @media screen and (max-width: 768px) {
    .header {
      padding: 4rem 1rem;
    }
  
    .header h1 {
      font-size: 2.5rem;
    }
  
    .header p {
      font-size: 1.2rem;
    }
  
    .container {
      padding: 0 1rem;
    }
  
    .platformGrid,
    .processSteps,
    .featureGrid,
    .techGrid {
      grid-template-columns: 1fr;
    }
  }